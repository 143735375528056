import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/Zap.json' // 合约abi
import web3Obj from '../utils/web3'
import store from '@/store'

const contractAddress = config.zap
const contractInstance = new web3Obj.web3.eth.Contract(contractAbi, contractAddress)

/**
 * estimateZapInSwap
 * @return amount
 */
const estimateZapInSwap = async (_tokenToZap, _tokenAmountIn, _lpToken) => {
  try {
    const resp = await contractInstance.methods
      .estimateZapInSwap(_tokenToZap, web3Obj.toWei(_tokenAmountIn), _lpToken)
      .call({})
    return resp
  } catch (error) {
    return {
      swapAmountIn: 0,
      swapAmountOut: 0
    }
  }
}

const estimateZapOutSwap = async (_lpToken, _lpTokenAmount, _tokenToReceive) => {
  try {
    const resp = await contractInstance.methods
      .estimateZapOutSwap(_lpToken, web3Obj.toWei(_lpTokenAmount), _tokenToReceive)
      .call({})
    return (resp)
  } catch (error) {
    return 0
  }
}
/**
 * zapInToken 組lp
 * _tokenToZap
 * _tokenAmountIn
 * _lpToken
 * _tokenAmountOutMin
 */
const zapInToken = async (_tokenToZap, _tokenAmountIn, _lpToken, _tokenAmountOutMin = 0) => {
  try {
    const min = await estimateZapInSwap(_tokenToZap, _tokenAmountIn, _lpToken)
    _tokenAmountOutMin = web3Obj.fromWei(min.swapAmountOut) * 0.95
    const resp = await contractInstance.methods
      .zapInToken(_tokenToZap, web3Obj.toWei(_tokenAmountIn), _lpToken, web3Obj.toWei(_tokenAmountOutMin))
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 *
 * @param {*} _lpToken
 * @param {*} _tokenToReceive
 * @param {*} _lpTokenAmount
 * @param {*} _tokenAmountOutMin
 * @param {*} _totalTokenAmountOutMin
 * @returns
 */

const zapOutToken = async (_lpToken, _tokenToReceive, _lpTokenAmount, _tokenAmountOutMin = 0, _totalTokenAmountOutMin = 0) => {
  try {
    const min = await estimateZapOutSwap(_lpToken, _lpTokenAmount, _tokenToReceive)
    _tokenAmountOutMin = web3Obj.fromWei(min.swapAmountOut) * 0.95
    _totalTokenAmountOutMin = _tokenAmountOutMin * 2
    const resp = await contractInstance.methods
      .zapOutToken(_lpToken, _tokenToReceive, web3Obj.toWei(_lpTokenAmount), web3Obj.toWei(_tokenAmountOutMin), web3Obj.toWei(_totalTokenAmountOutMin))
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    console.log(error)
    return web3Obj.failResult(error)
  }
}
export default {
  estimateZapInSwap,
  estimateZapOutSwap,
  zapInToken,
  zapOutToken
}
