<template>
  <VanPopup
    v-model="removeShow"
    style="width: 83%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="lp-wrap">
      <div class="title-wrap">
        <div class="title">
          <div class="title">{{$t('common.remove')}} MUSD-MEB-LP</div>
          <div class="close-icon" @click="closePop">
            <van-icon name="cross" />
          </div>
        </div>
      </div>
      <div class="lp-box">
        <div class="lp-balance">
          <div>{{  $t('common.imputRemoveAmount', {token: 'LP'}) }}</div>
          <div>{{ $t('common.balance') }}: {{ lpBalance || 0 }}</div>
        </div>
        <div class="input-box">
          <input
            class="input-border"
            placeholder=""
            v-model="lpAmount"
            oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)"
          />
          <div class="max-btn" @click="handleToMax">Max</div>
        </div>
      </div>
      <div class="lp-btn-groups">
        <div class="approve-btn" v-if="btnLoading">
          <VanLoading size="20" color="#fff" />
        </div>
        <template v-else>
          <div class="approve-btn" v-if="!allanceFlag" @click="approveLp">
            <span v-if="!authLoading"> {{$t('ecology.grant')}} </span>
            <van-loading size="20" v-else color="#fff" />
          </div>
          <div class="approve-btn" v-else @click="handleRemove">
            <span v-if="!removeLoading"> {{ $t('common.remove') }}</span>
            <van-loading size="20" v-else color="#fff" />
          </div>
        </template>
      </div>
    </div>
  </VanPopup>
</template>
<script>
import config from '@/config/app.config'
import contractToken from '../contracts/contractToken'
import contractZap from '../contracts/contractZap'

export default {
  name: 'Remove',
  props: {
    // lp的地址
    lpObj: {
      type: Object
    },
    lpbalance: {
      type: Number
    },
    allowanceLP: {
      type: Number
    }
  },
  data () {
    return {
      removeShow: true,
      tokenMusd: config.MUSD,
      tokenMeb: config.MEB,
      lpAddress: config.MUSDMEB,
      lpAmount: null, // lp输入值
      authLoading: false,
      removeLoading: false,
      btnLoading: true,
      lpBalance: 0, // lp剩
      lpPrice: 0, // lp单价
      lpAllowance: 0, // lp授权额度
      allanceFlag: false // 授权标识 true 已经授权 false未授权
    }
  },
  computed: {
    stakeTips () {
      return this.$t('common.stakeTips')
    },
    staking () {
      return this.$t('staking.removeLiquidty')
    },
    // lp总价
    lpTotalPrice () {
      return this.$gbUtils.formatTwoBalance(this.lpPrice * this.lpAmount)
    }
  },
  methods: {
    init () {
      this.getBalance()
      // 获取授权额度
      this.getAllowance()
    },
    // 跳转到pancake
    handleToPancake () {
      window.open(`${config.pancakeUrl + config.pancakePool}`)
    },
    // 弹窗显示及隐藏
    closePop () {
      this.$emit('close')
    },
    // 获取剩
    async getBalance () {
      const lpResp = await this.$web3.balanceOf(this.lpAddress)
      if (!lpResp.success) return
      this.lpBalance = this.$gbUtils.formatTwoBalance(lpResp.result) || 0
    },
    async getAllowance () {
      // 获取授权额度
      this.btnLoading = true
      const allanceResp1 = await contractToken.allowance(
        this.lpAddress,
        config.zap
      )
      this.btnLoading = false
      // console.log('lpAllowance', allanceResp1.result)
      if (allanceResp1.success) {
        this.lpAllowance = allanceResp1.result
        if (allanceResp1.result > 0) this.allanceFlag = true
        else this.allanceFlag = false
      }
    },
    // lp最大
    handleToMax () {
      this.lpAmount = this.lpBalance
    },
    // 授权lp
    async approveLp () {
      this.authLoading = true
      this.$toast.loading({ duration: 60000 })
      const resp = await contractToken.approve(
        this.lpAddress,
        config.zap
      ) // 授权lptoken
      this.$toast.clear()
      this.authLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.getAllowance()
      this.authLoading = false
    },
    // 移除流动性
    async handleRemove () {
      if (this.removeLoading) return
      if (!this.lpAmount || this.lpAmount > this.lpBalance) {
        return
      }
      this.removeLoading = true
      const resp = await contractZap.zapOutToken(this.lpAddress, this.tokenMusd, this.lpAmount)
      this.removeLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.$toast('success')
      this.lpAmount = ''
      this.init()
    }
  },
  watch: {
    lpAmount (val) {
      const inputVal = parseFloat(val)
      if (inputVal > 0 && inputVal > this.lpAllowance) {
        this.allanceFlag = false
      } else {
        this.allanceFlag = this.lpAllowance > 0
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.lp-wrap {
  padding: 20px;
  box-sizing: border-box;
  .title-wrap {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      color: #2a2b33;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #6667FF !important;
      border-radius: 10px;
      text-align: center;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
    .to-pancake {
      padding-top: 5px;
      font-size: 12px;
      color: #6667FF;
      cursor: pointer;
      .icon {
        font-size: 12px;
      }
    }
  }
  .lp-box {
    .lp-balance {
      font-size: 12px;
      font-weight: bold;
      color: #16172a;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:last-child {
        font-weight: normal;
      }
    }
    .input-box {
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 2px 0px 2px 10px;
      box-sizing: border-box;
      justify-content: space-between;
      background: #f0f0f0;
      input {
        background: #f0f0f0;
        border: none;
      }
      .max-btn {
        cursor: pointer;
        width: 40px;
        height: 30px;
        background: #6667FF;
        opacity: 1;
        border-radius: 10px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
        color: #fff;
      }
    }
    .meb-amount {
      padding-top: 10px;
      color: #6667FF;
      font-size: 12px;
      font-weight: bold;
    }
    .meb-balance {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .lp-btn-groups {
    text-align: center;
    margin-top: 15px;
    bottom: 20px;
    left: 50%;
    width: 100%;
    .approve-btn {
      height: 38px;
      background-color: #6667FF;
      line-height: 38px;
      border-radius: 10px;
      color: #000;
      font-weight: bold;
      margin: 0 auto;
      cursor: pointer;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
