<template>
  <div class="dialog-wrapper" @touchmove.prevent>
    <div class="dialog-box">
      <div class="dialog">
        <div class="title">{{ home.getmulp }}</div>
        <div class="address">
          <input type="text" class="input" v-model="amount" :placeholder="home.pleaseInputLpAmount" />
        </div>
        <div class="amount-list">
          <div class="amount-item" v-for="item in amountList" :key="item" :class="{ actvie: amount == item }"
            @click="() => amount = item">{{ item }}</div>
        </div>
        <div class="flex-wrap">
          <div class="tips">≈ {{ $gbUtils.formatBalance(lpPrice * amount * 2) }} USDT</div>
          <div class="tips">{{ home.balance }}：{{ $gbUtils.formatBalance(usdtBalacne) }} USDT</div>
        </div>
        <div class="confirm">
          <VanButton class="btn" @click="approveUsdt" :loading="approveLoading" v-if="!authUsdt">{{ home.supplyLP }}
          </VanButton>
          <van-button class="btn" @click="handleToSubmit" :loading="exchangeLoading"
            :disabled="amount <= 0 || (amount * lpPrice * 2 > usdtBalacne)" v-else>{{ home.confirm }}</van-button>
        </div>
        <div class="tips text"  @click="() => $parent.handleToApp('https://pancakeswap.finance/add/0x7268B479eb7CE8D1B37Ef1FFc3b82d7383A1162d/0x55d398326f99059fF775485246999027B3197955')">*{{ home.getlpTips }}</div>
      </div>
    </div>
    <img src="@/assets/close.png" class="delete-icon" @click="handleClose" />
  </div>
</template>

<script>
import config from '@/config/app.config'
import contractToken from '@/contracts/contractToken'
import contractBusiness from '../contracts/contractBusiness'

export default {
  name: 'GetLpDialog',
  props: [],
  data () {
    return {
      usdtAddress: '',
      amount: 0,
      usdtBalacne: 0,
      authUsdt: false,
      approveLoading: false,
      lpPrice: 0,
      exchangeLoading: false,
      amountList: [100, 300, 500, 1000]
    }
  },
  computed: {
    home () {
      return this.$t('home')
    },
    common () {
      return this.$t('common')
    }
  },
  methods: {
    async init () {
      this.getLpPrice()
      await this.getUsdtAddress()
      this.getUsdtBalance()
      this.getAllowance()
    },
    async getUsdtAddress () {
      const resp = await contractBusiness.usdt()
      if (!resp.success) return
      this.usdtAddress = resp.result
    },
    async getUsdtBalance () {
      const resp = await this.$web3.balanceOf(this.usdtAddress)
      if (!resp.success) return
      this.usdtBalacne = resp.result
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        this.usdtAddress,
        config.lpBusiness
      )
      this.approveLoading = false
      if (!resp.success) return
      const lpAllowance = resp.result
      this.authUsdt = lpAllowance > 0
    },
    // 获取lp对应的usdt数量
    async getLpPrice () {
      const resp = await contractBusiness.calcLpToUsdt()
      if (!resp.success) return
      this.lpPrice = resp.result || 0
    },
    async handleToSubmit () {
      this.exchangeLoading = true
      const resp = await contractBusiness.usdtToLp(this.amount)
      this.exchangeLoading = false
      if (!resp.success) return
      this.$toast('success')
      this.amount = ''
      this.init()
    },
    handleClose () {
      this.$emit('close')
    },
    async approveUsdt () {
      if (this.approveLoading) return
      this.approveLoading = true
      const resp = await contractToken.approve(
        this.usdtAddress,
        config.lpBusiness
      ) // 授权usdt
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.authUsdt = true
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  max-width: 450px;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  text-align: center;

  .dialog-box {
    padding: 0 48px;

    .dialog {
      text-align: left;
      text-align: center;
      background: #ffffff;
      border-radius: 15px;
      margin: 224px auto 0;
      box-sizing: border-box;
      padding: 24px 0;
      color: #000000;

      .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }

      .address {
        padding: 0 18px;
        margin: 34px 0 10px;

        .input {
          height: 40px;
          background: #F3F3F3;
          border-radius: 5px;
          width: 100%;
          border: none;
          box-sizing: border-box;
          padding: 0 12px;
          font-size: 11px;

          &::placeholder {
            color: #878787;
            // color: rgba(0, 0, 0, 0.3);
          }
        }
      }

      .tips {
        text-align: left;
        padding: 0 18px;
        font-size: 12px;
        line-height: 24px;

        &.text {
          color: red;
        }
      }

      .tips:nth-child(2n) {
        padding-left: 24px;
      }

      .confirm {
        margin-top: 10px;
        padding: 0 15px;

        .btn {
          width: 100%;
          height: 43px;
          line-height: 43px;
          background: linear-gradient(90deg, #622AFF 0%, #5E12E7 99%);
          border-radius: 22px;
          font-size: 13px;
          font-weight: 500;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }

  .delete-icon {
    cursor: pointer;
    margin: 30px auto 0;
    width: 30px;
    height: 30px;
  }

  .amount-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 18px 5px 18px;

    .amount-item {
      flex-shrink: 0;
      width: 23%;
      border-radius: 20px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: 1px solid #622AFF;
      margin-bottom: 5px;

      &.actvie {
        background: #622AFF;
        color: #fff;
      }
    }
  }
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
    .tips {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}
</style>
