<template>
  <VanPopup
    v-model="addShow"
    style="width: 83%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="lp-wrap">
      <div class="title-wrap">
        <div class="title">
          <div class="title">{{$t('common.getLp')}}</div>
          <div class="close-icon" @click="closePop">
            <van-icon name="cross" />
          </div>
        </div>
        <div class="to-pancake" @click="handleToMusd">
          {{ $t('common.getMusd') }}
          <van-icon name="share" />
        </div>
      </div>
      <div class="lp-box">
        <div class="lp-balance">
          <div>{{ $t('common.inputTokenAmount', {token: 'MUSD'})}}</div>
          <div>{{ $t('common.balance') }}: {{ musdBalance }}</div>
        </div>
        <div class="input-box">
          <input
            class="input-border"
            placeholder=""
            v-model="musdAmount"
            oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)"
          />
          <div class="max-btn" @click="handleToMax">Max</div>
        </div>
        <!-- 預計獲得 -->
        <div class="meb-amount">LP{{$t('common.balance')}}：{{ lpBalance }}</div>
      </div>
      <div class="lp-btn-groups">
        <div class="approve-btn" v-if="btnLoading">
          <van-loading  size="20" color="#fff" />
        </div>
        <template v-else>
          <div
            class="approve-btn"
            v-if="authUSDT && authMEB"
            @click="handleSupply"
          >
            <span v-if="!getLPLoading">{{ $t('common.getLp') }}</span>
            <van-loading  size="20" v-else color="#fff" />
          </div>
          <div
            class="approve-btn"
            v-if="!authUSDT"
            @click="approveUSDTContract"
            ref="authUSDT"
          >
            <span v-if="!authUSDTLoading">{{$t('ecology.grant')}} MUSD</span>
            <van-loading  size="20" v-else color="#fff" />
          </div>
          <div
            class="approve-btn"
            ref="authMEB"
            @click="approveMEBContract"
            v-else-if="!authMEB"
          >
            <span v-if="!authMEBLoading">{{$t('ecology.grant')}} MEB</span>
            <van-loading  size="20" v-else color="#fff" />
          </div>
        </template>
      </div>
    </div>
  </VanPopup>
</template>
<script>
import config from '@/config/app.config'
import contractToken from '../contracts/contractToken'
import contractZap from '../contracts/contractZap'

export default {
  name: 'liquidity',
  data () {
    return {
      addShow: true,
      tokenMusd: config.MUSD,
      tokenMeb: config.MEB,
      lpAddress: config.MUSDMEB,
      musdAmount: null, // usdt输入值
      mebAmount: 0, // 需要的meb数量
      authUSDTLoading: false,
      authMEBLoading: false,
      getLPLoading: false, // 获取loading
      btnLoading: true,
      authUSDT: false,
      authMEB: false,
      musdBalance: 0, // usdt余额
      mebBalance: 0, // meb余额
      lpBalance: 0, // lp
      usdtAllowance: 0, // usdt授权额度
      mebAllowance: 0 // meb授权额度
    }
  },
  props: {
    userStakeValue: {
      default () {
        return 0
      }
    }
  },
  computed: {
  },
  methods: {
    init () {
      this.getBalance()
      // 获取授权额度
      this.getAllowance()
    },
    // 兌換
    handleToMusd () {
      window.open('https://musd.finance/#/musd')
    },
    closePop () {
      this.$emit('close')
    },
    // 监听USDT输入
    handleUSDTInput () {
      parseInt(this.musdAmount) < parseInt(this.usdtAllowance)
        ? (this.authUSDT = true)
        : (this.authUSDT = false)
      console.log('authMEB', this.authMEB)
    },
    // 授权mu
    async approveUSDTContract () {
      this.authUSDTLoading = true
      this.$refs.authUSDT.style.pointerEvents = 'none'
      const resp = await contractToken.approve(
        this.tokenMusd,
        config.zap
      ) // 授权usdt
      this.$refs.authUSDT.style.pointerEvents = 'auto'
      this.authUSDTLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      // console.log('签名回调', resp)
      this.$refs.authUSDT.style.pointerEvents = 'auto'
      this.authUSDTLoading = false
      this.authUSDT = true
      this.getAllowance()
    },
    // 授权MEB
    async approveMEBContract () {
      this.authMEBLoading = true
      this.$refs.authMEB.style.pointerEvents = 'none'
      const resp = await contractToken.approve(
        this.tokenMeb,
        config.zap
      )
      if (this.$refs.authMEB) { this.$refs.authMEB.style.pointerEvents = 'auto' }
      this.authMEBLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      // console.log('签名回调', resp)
      this.authMEB = true
      this.getAllowance()
    },
    // 获取余额
    async getBalance () {
      const usdtResp = await this.$web3.balanceOf(this.tokenMusd)
      if (usdtResp.success) {
        this.musdBalance = this.$gbUtils.formatTwoBalance(usdtResp.result) || 0
      }
      const lpresp = await this.$web3.balanceOf(this.lpAddress)
      if (lpresp.success) {
        this.lpBalance = this.$gbUtils.formatTwoBalance(lpresp.result) || 0
      }
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.btnLoading = true
      const allanceResp1 = await contractToken.allowance(
        this.tokenMusd,
        config.zap
      )
      // console.log('usdtAllowance', allanceResp1.result)
      if (allanceResp1.success) {
        this.usdtAllowance = allanceResp1.result
        this.authUSDT = this.usdtAllowance > 0
      }
      const allanceResp2 = await contractToken.allowance(
        this.tokenMeb,
        config.zap
      )
      // console.log('mebAllowance', allanceResp2.result)
      if (allanceResp2.success) {
        this.mebAllowance = allanceResp2.result
        this.authMEB = allanceResp2.result > 0
      }
      // console.log(this.authUSDT)
      // console.log(this.authMEB)
      this.btnLoading = false
      // console.log(``, );
    },
    // usdt最大
    handleToMax () {
      this.musdAmount = this.musdBalance
    },
    // 获取
    async getMinAmount () {
      const resp = await contractZap.estimateZapInSwap(this.tokenMusd, this.musdAmount, this.lpAddress)
      this.mebAmount = this.$gbUtils.formatBalance(this.$web3.fromWei(resp.swapAmountIn))
    },
    // 添加流动性
    async handleSupply () {
      if (this.getLPLoading) return
      if (!this.musdAmount) {
        return
      }
      this.getLPLoading = true
      const resp = await contractZap.zapInToken(
        this.tokenMusd,
        this.musdAmount,
        this.lpAddress
      )
      this.$emit('changeLoading', false)
      this.getLPLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.$toast('success')
      this.musdAmount = ''
      this.init()
    }
  },
  watch: {
    musdAmount (val) {
      if (val) {
        this.getMinAmount()
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.lp-wrap {
  padding: 20px;
  box-sizing: border-box;
  .title-wrap {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      color: #2a2b33;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #6667FF !important;
      border-radius: 10px;
      text-align: center;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
    .to-pancake {
      padding-top: 5px;
      font-size: 12px;
      color: #6667FF;
      cursor: pointer;
      .icon {
        font-size: 12px;
      }
    }
  }
  .lp-box {
    .lp-balance {
      font-size: 12px;
      font-weight: bold;
      color: #16172a;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:last-child {
        font-weight: normal;
      }
    }
    .input-box {
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 2px 0px 2px 10px;
      box-sizing: border-box;
      justify-content: space-between;
      background: #f0f0f0;
      input {
        background: #f0f0f0;
        border: none;
      }
      .max-btn {
        cursor: pointer;
        width: 40px;
        height: 30px;
        background: #6667FF;
        opacity: 1;
        border-radius: 10px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
        color: #fff;
      }
    }
    .meb-amount {
      padding-top: 10px;
      color: #6667FF;
      font-size: 12px;
      font-weight: bold;
    }
    .meb-balance {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .lp-btn-groups {
    text-align: center;
    margin-top: 10px;
    bottom: 20px;
    left: 50%;
    width: 100%;
    .approve-btn {
      height: 38px;
      background-color: #6667FF;
      line-height: 38px;
      border-radius: 10px;
      color: #000;
      font-weight: bold;
      margin: 0 auto;
      cursor: pointer;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>
