<template>
  <div class="home-module">
    <div class="logo-box">
      <img src="../../assets/home/home_logo .png" class="home-logo" />
    </div>
    <div class="logo-box">
      <div class="slogan1">{{ home.Develop }}</div>
      <div class="slogan2">{{ home.Provide }}</div>
    </div>
    <div class="banner-box">
      <van-swipe class="banner" :autoplay="5000" indicator-color="white">
        <van-swipe-item class="banner-item" @click="$gbUtils.handleToPage('/advertisement?id=6')">
          <img src="@/assets/banner/banner7.png" style="width: 100%; height: 150px; border-radius: 15px" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="btn-div">
      <van-button class="btn linear-bg" @click="$gbUtils.handleToPage('/dao')">{{ home.MetaBillPool }}</van-button>
      <div class="_flex">
        <van-button class="btn linear-bg" @click="addLiquidity">{{ $t('common.getLp') }}</van-button>
        <van-button class="btn" @click="removeLiquidity">{{ $t('common.removeLp') }}</van-button>
      </div>
      <van-button class="btn" @click="handleToCreateAccount" v-if="!parentAddress">{{ home.joinmeta }}</van-button>
      <van-button class="btn" @click="$gbUtils.handleToPage('/otcTransaction')">
        <span> {{ home.maiOtc }}</span>
        <div class="new">NEW</div>
      </van-button>
      <van-button class="btn" @click="$gbUtils.handleToPage('/maiAirdrop')">
        <span> {{ home.maiAirdrop }}</span>
        <div class="new">NEW</div>
      </van-button>
      <!-- <van-button class="btn" @click="handleToApp('https://pancakeswap.finance/swap?outputCurrency=0x7268B479eb7CE8D1B37Ef1FFc3b82d7383A1162d')">{{  home.Get  }}MEB</van-button> -->
      <!-- <van-button class="btn" @click="handleGetLp()">{{  home.Get  }} MEB-USDT LP</van-button> -->
    </div>
    <CreateAccountDialog v-if="showCreateAccountDialog" @register="handleRegister"
      @handleToCreateAccount="handleToCreateAccount" :createLoading="createLoading" :approveLoading="approveLoading"
      :authLp="authLp" :toBindAddress="toBindAddress" :lpBalance="lpBalance" />

    <GetLpDialog v-if="showGetLpDialog" @close="handleGetLp" />

    <!-- 一键获取lp -->
    <AddLiquidity v-if="addShow" ref="addLiquidity" @close="addLiquidity" />
    <!-- 移除lp -->
    <RemoveLiquidity v-if="removeShow" @close="removeLiquidity" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config/app.config'
import lighting from '@/contracts/lighting.js'
import contractToken from '@/contracts/contractToken'
import CreateAccountDialog from '@/components/CreateAccountDialog.vue'
import GetLpDialog from '@/components/GetLpDialog.vue'
import AddLiquidity from '../../components/AddLiquidity.vue'
import RemoveLiquidity from '../../components/RemoveLiquidity.vue'

export default {
  components: { CreateAccountDialog, GetLpDialog, AddLiquidity, RemoveLiquidity },
  data () {
    return {
      addShow: false,
      removeShow: false,
      sideShow: false,
      dropdown: 0,
      parentAddress: '',
      showCreateAccountDialog: false,
      showGetLpDialog: false,
      authLp: false,
      approveLoading: false,
      createLoading: false, // 创建账户
      lpBalance: 0,
      headSn: '',
      cost: 0, // 单盏灯成本（lp）
      rewardDetails: {} // 奖励详情
    }
  },
  computed: {
    home () {
      return this.$t('home')
    },
    micStake () {
      return this.$t('micStake')
    },
    ...mapState(['lang'])
  },
  methods: {
    addLiquidity () {
      this.addShow = !this.addShow
    },
    removeLiquidity () {
      this.removeShow = !this.removeShow
    },
    sideClose (index) {
      this.dropdown = index
      this.sideShow = !this.sideShow
    },
    handleclose () {
      this.sideShow = !this.sideShow
    },
    changeLanguage () {
      const lang = this.lang === 'ZH' ? 'EN' : 'ZH'
      this.changeLang(lang)
      this.$emit('changeLange', lang)
    },
    // 获取账户
    async getAccounts () {
      this.$emit('changeLoading', true)
      const resp = await lighting.accounts()
      this.$emit('changeLoading', false)
      if (!resp.success) return
      this.parentAddress =
        resp.result.head === '0x0000000000000000000000000000000000000000'
          ? ''
          : resp.result.head
      this.rewardDetails = resp.result
      const head = await lighting.accounts(resp.result.head)
      this.headSn = head.result.sn
    },
    // 创建账户弹窗
    handleToCreateAccount () {
      if (this.createLoading || this.approveLoading) return
      this.showCreateAccountDialog = !this.showCreateAccountDialog
    },
    // 创建账号
    async handleRegister (parentAddress) {
      if (this.createLoading) return
      if (
        parseFloat(this.headSn) >= 67108864 ||
        parseFloat(this.rewardDetails.sn) >= 2147483648
      ) {
        return this.$toast(this.home.fail)
      }
      if (this.lpBalance < this.cost * 1) {
        return this.$toast(this.micStake.lack)
      }
      this.createLoading = true
      const resp = await lighting.register(parentAddress)
      this.createLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.$toast('success')
      this.showCreateAccountDialog = false
      this.init()
    },
    // 获取LP
    async getLpAddress () {
      const resp = await lighting.getLpAddress()
      if (resp.success) {
        this.lpAddress = resp.result
        const lpBalanceResp = await this.$web3.balanceOf(this.lpAddress)
        if (lpBalanceResp.success) {
          this.lpBalance = this.$gbUtils.formatTwoBalance(lpBalanceResp.result)
        }
        this.getAllowance()
      }
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        this.lpAddress,
        config.lightingAddress
      )
      if (resp.success) {
        this.lpAllowance = resp.result
        this.authLp = this.lpAllowance > 0
        this.payStatus = true
      }
      this.approveLoading = false
    },
    // 授权LP
    async approveLP () {
      if (this.approveLoading) return
      this.approveLoading = true
      const resp = await contractToken.approve(
        this.lpAddress,
        config.lightingAddress
      ) // 授权LP
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.authLp = true
      if (this.showCreateAccountDialog) {
        this.$nextTick(() => {
          this.showCreateAccountDialog = false
          this.showCreateAccountDialog = true
        })
      }
    },
    // 单盏灯成本（lp）
    async getCost () {
      const resp = await lighting.expend()
      if (resp.success) {
        this.cost = resp.result
      }
    },
    handleToApp (url) {
      window.location.href = url
    },
    handleGetLp () {
      this.showGetLpDialog = !this.showGetLpDialog
    }
  },
  mounted () {
    this.toBindAddress = this.$route.query.address
      ? this.$route.query.address
      : ''
    this.getAccounts()
    this.getLpAddress()
    this.getCost()
  }
}
</script>

<style lang="scss" scoped>
.home-module {
  width: 100%;
  min-height: 100vh;
  background: #18191A;
  box-sizing: border-box;
  padding: 68px 0 30px;

  .logo-box {
    box-sizing: border-box;
    padding: 0 24px;
    text-align: center;

    .home-logo {
      width: 114px;
      height: 104px;
    }

    .slogan1 {
      margin: 40px auto 0;
      font-size: 23px;
      font-weight: 500;
      color: #ffffff;
      width: fit-content;
      background: linear-gradient(90deg, #FFAD43 22.52%, #FF7C6B 38.33%, #FD5090 53.24%, #662BFC 65.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .slogan2 {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.btn-div {
  margin-top: 20px;
  padding: 0px 48px;

  .btn {
    width: 100%;
    height: 56px;
    background: #6667FF;
    border-radius: 44px 44px 44px 44px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 24px;
    position: relative;

    &.linear-bg {
      background: linear-gradient(90deg, #e94b9f 0%, #ffb13e 100%);
    }

    .new {
      padding: 5px 8px;
      background: linear-gradient(135deg, #FFAF66 0%, #FF6666 100%);
      box-shadow: 0px 0px 10px 0px rgba(255, 104, 104, 0.2);
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      font-size: 12px;
      font-weight: 500;
      color: #F5F5F5;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }
}

.bottom {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
}

.banner-box {
  padding: 0 15px;
  margin-top: 20px;

  .banner {
    width: 100%;

    .banner-item {
      width: 100%;
      height: 150px;
      border-radius: 15px;
      background: linear-gradient(0deg, #383a3a 0%, #242525 100%);
      line-height: 150px;
      text-align: center;
    }
  }
}

._flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    width: 48%;
  }
}
</style>
