import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/LpBusiness.json' // 业务合约
import web3Obj from '../utils/web3'
import store from '@/store'

const contractAddress = config.lpBusiness
const contractInstance = new web3Obj.web3.eth.Contract(contractAbi, contractAddress)
const slippage = web3Obj.toWei(1.005)

// 获取usdt
const usdt = async () => {
  try {
    const resp = await contractInstance.methods
      .usdt()
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}
/**
 * 獲取lp的价值
 * @return amount
 */
const calcLpToUsdt = async () => {
  const amount = web3Obj.toWei(1)
  try {
    var balance = await contractInstance.methods
      .calcLpToUsdt(amount, slippage)
      .call({})
    balance = web3Obj.fromWei(balance)
    return web3Obj.successResult(balance)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 兑换
 */
const usdtToLp = async (amount) => {
  amount = web3Obj.toWei(amount)

  try {
    const resp = await contractInstance.methods
      .usdtToLp(amount, slippage)
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

export default {
  usdt,
  calcLpToUsdt,
  usdtToLp
}
