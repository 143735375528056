import web3 from '../utils/web3'
import tokenAbi from '@/abi/token.json'
import pairAbi from '@/abi/pair.json'
import store from '@/store'

/**
 *
 * @param {tokenAddress} 合约地址
 * @returns
 */
const getSymbol = async (tokenAddress) => {
  const tokenContract = await new web3.web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    const resp = await tokenContract.methods.symbol().call({})
    return web3.successResult(resp)
  } catch (error) {
    return web3.failResult(error)
  }
}

/**
 *  allowance, 授权dex使用币的额度
 * @tokenAdree 交易代币
 * @toAddress 授权地址
 * @return 额度
 */
const allowance = async (tokenAddress, toAddress) => {
  const tokenContract = await new web3.web3.eth.Contract(tokenAbi, tokenAddress)
  const currentAccount = store.state.myAcount // 当前钱包账户
  try {
    var amount = await tokenContract.methods
      .allowance(currentAccount, toAddress)
      .call({
        from: currentAccount
      })
    amount = web3.fromWei(amount)
    return web3.successResult(amount)
  } catch (error) {
    return web3.failResult(error)
  }
}
/**
 * approveContract 授权dex使用币
 * @tokenAddress 交易代币
 * @toAddress 授权地址
 * @param inputValue 输入的数量(默认很大---一次性授权，避免重复授权)
 */
const approve = async (
  tokenAddress,
  toAddress,
  inputValue = 10000000000000000
) => {
  await web3.connectWallet()
  const stringValue = web3.toWei(inputValue)
  const tokenContract = await new web3.web3.eth.Contract(tokenAbi, tokenAddress)
  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts'
  }) // 当前钱包账户
  try {
    const resp = await tokenContract.methods
      .approve(toAddress, stringValue)
      .send({
        from: accounts[0], ...web3.gasPriceObj
      })
    return web3.successResult(resp)
  } catch (error) {
    console.log(error)
    return web3.failResult(error)
  }
}

/**
 * 转账
 * @fromAddress 当前地址
 * @toAddress 目的地址
 * @value 数额
 * @tokenAddress 代币地址
 */
const transfer = async (toAddress, amount, tokenAddress) => {
  amount = web3.toWei(amount)
  const currentAccount = store.state.myAcount
  const tokenContract = await new web3.web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    const resp = await tokenContract.methods.transfer({
      from: currentAccount,
      to: toAddress,
      value: amount
    })
    console.log(resp)
    return web3.successResult(resp)
  } catch (err) {
    console.log(err)
    return web3.failResult(err)
  }
}

/**
 * 查询某个账号的代币余额
 * @param {contractAddress} 币种合约
 * @param {currentAccount} 账户地址
 * @returns 代币数量
 */

const balanceOf = async (tokenAddress, account) => {
  const currentAccount = store.state.myAcount
  const tokenContract = await new web3.web3.eth.Contract(tokenAbi, tokenAddress)
  try {
    var balance = await tokenContract.methods
      .balanceOf(account || currentAccount)
      .call({})
    balance = web3.fromWei(balance)
    return web3.successResult(balance)
  } catch (error) {
    return web3.failResult(error)
  }
}

const totalSuplly = async (tokenAddress) => {
  const tokenContract = await new web3.web3.eth.Contract(pairAbi, tokenAddress)
  try {
    var balance = await tokenContract.methods.totalSupply().call({})
    balance = web3.fromWei(balance)
    return web3.successResult(balance)
  } catch (error) {
    return web3.failResult(error)
  }
}

const tokenObj = {
  getSymbol,
  allowance,
  approve,
  transfer,
  balanceOf,
  totalSuplly
}

export default tokenObj
